.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  @apply bg-primary rounded-full h-4 w-4;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  @apply bg-primary rounded-full h-4 w-4;
  cursor: pointer;
}

.disabled-slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  @apply bg-secondary-darker rounded-full h-4 w-4;
}

.disabled-slider::-moz-range-thumb {
  @apply bg-secondary-darker rounded-full h-4 w-4;
  cursor: not-allowed;
}

.custom-slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  @apply bg-custom-area-ratio rounded-full h-4 w-4;
  cursor: pointer;
}

.custom-slider::-moz-range-thumb {
  @apply bg-custom-area-ratio rounded-full h-4 w-4;
  cursor: pointer;
}

.disabled-custom-slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  @apply bg-secondary-darker rounded-full h-4 w-4;
}

.disabled-custom-slider::-moz-range-thumb {
  @apply bg-secondary-darker rounded-full h-4 w-4;
  cursor: not-allowed;
}