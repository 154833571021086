.range-value {
  position: absolute;
  top: -50%;
}

.range-value span {
  @apply bg-secondary-dark text-gray-600;
  width: 34px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  font-size: 13px;
  display: block;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 6px;
}
.range-value span:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 10px solid #e0dcdc;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  margin-top: -1px;
}
