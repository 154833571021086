.react-datepicker__header__dropdown,
.react-datepicker__header__dropdown--select {
  display: flex;
  align-items: center;
}

.react-datepicker__navigation,
.react-datepicker__navigation--previous {
  top: 5px;
}

.react-datepicker__current-month,
.react-datepicker__current-month--hasYearDropdown,
.react-datepicker__current-month--hasMonthDropdown {
  margin-top: -4px;
  margin-bottom: 4px;
}

.react-datepicker-popper,
.month-picker {
  width: 220px;
}

.month-picker .react-datepicker__month-text {
  width: 50px;
}

.month-picker .react-datepicker__navigation,
.react-datepicker__navigation--previous {
  top: 9px;
}

.react-datepicker__month-text--keyboard-selected,
.react-datepicker__day--keyboard-selected {
  @apply bg-transparent text-black font-normal;
}

.react-datepicker__day--today {
  @apply font-semibold;
}

.react-datepicker__month-text:hover,
.react-datepicker__day:hover {
  @apply bg-primary text-white;
}
