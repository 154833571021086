@import "./leaflet.css";
@import "./leaflet-draw.css";
@import "./slick.css";
@import "./slick-theme.css";
@import "./react-datepicker.css";
@import "./slider.css";
@import "./radius-dropdown.css";
@import url("https://rsms.me/inter/inter.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

.react-select input,
.react-select input:focus {
  @apply shadow-none ring-0 border-0 outline-none;
}

.slick-prev::before,
.slick-next::before {
  @apply inline-block bg-white shadow text-gray-800 opacity-100 rounded-full p-2;
}
.slick-disabled {
  @apply opacity-100;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

body {
  --toastify-icon-color-success: #10b981;
}

.sr-only {
  display: none;
}

:not(
    .leaflet-editing-icon,
    .search-location-marker,
    .marker-cluster-custom
  ).leaflet-marker-icon {
  background: none !important;
  border: none !important;
  outline: none !important;
}

.cluster-marker {
  @apply text-white bg-black font-semibold flex justify-center items-center rounded-full text-center;
}

.leaflet-draw-tooltip-visible {
  visibility: visible;
}

.leaflet-draw-tooltip-visible:before {
  left: -1px;
}

@keyframes fade {
  from {
    opacity: 0.8;
  }
}

.blink {
  animation: fade 1s infinite alternate;
}

@-moz-keyframes oscillate {
  0% {
    -moz-transform: scale(0.1);
    transform: scale(0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
    filter: none;
  }

  100% {
    -moz-transform: scale(0.9);
    transform: scale(0.9);
    opacity: 0;
  }
}

@-webkit-keyframes oscillate {
  0% {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
    filter: none;
  }

  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    opacity: 0;
  }
}

@keyframes oscillate {
  0% {
    -ms-transform: scale(0.1);
    -o-transform: scale(0.1);
    transform: scale(0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }

  100% {
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    transform: scale(0.9);
    opacity: 0;
  }
}

.search-location-marker {
  border-radius: 100%;
  box-shadow: 1px 1px 8px 0 rgb(0 0 0 / 75%);
  background-color: #822c42;
  margin-left: -8px;
  margin-top: -8px;
  width: 16px;
  height: 16px;
  transform: translate3d(800px, 581px, 0px);
  z-index: 581;
  outline: none;
}

.search-location-marker:after {
  content: "";
  -webkit-border-radius: 100%;
  border-radius: 100%;
  height: 300%;
  width: 300%;
  position: absolute;
  margin: -100% 0 0 -100%;
  box-shadow: 0 0 6px 2px #822c42;
  -moz-animation: oscillate 1.2s ease-out;
  -o-animation: oscillate 1.2s ease-out;
  -webkit-animation: oscillate 1.2s ease-out;
  animation: oscillate 1.2s ease-out;
  -moz-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -moz-animation-delay: 1.1s;
  -o-animation-delay: 1.1s;
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s;
}

.gutter {
  background-repeat: no-repeat;
  background-position: 50%;
}

.gutter.gutter-horizontal {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==");
  cursor: col-resize;
}

html {
  @apply overscroll-y-none;
  -webkit-print-color-adjust: exact;

  &, & > body {
    z-index: 0 !important;
    @media (max-width: 1023px) {
      overflow-y: auto !important;
    }
  }

  & > body {
    @apply h-full w-full absolute lg:min-h-[100vh] lg:w-auto lg:relative;
    & *:not(#scrollable-table) {
      @apply overscroll-y-none;
    }
    & > #root {
      @apply flex flex-col h-full;
      & > div.bg-secondary-light:not(#liste, #statistik) {
        @apply h-full lg:h-auto;
      }
      & > div#transaktionen, & > div#share-deals, & > div#insolvenzen, & > div#merklisten, & > div#project-details {
        height: 100% !important;
      }
      & > div#statistik {
        @apply h-auto lg:h-full;
      }
      & > div#transaktionen, & > div#share-deals, & > div#insolvenzen, & > div#merklisten, & > div#merkliste, & > div#project-details  {
        min-height: calc(100vh - 3rem) !important;
      }
      
    }
    &:has(
      div#transaktionen,
      div#share-deals,
      div#insolvenzen,
      div#merklisten,
      div#merkliste,
      div#project-details
    ) {
      height: auto !important;
    }
    
    &:not(div#root > :has(div#karte), :has(div#liste)) {
      @apply min-h-screen;
    }

    &:has(div#root > div#merklisten, div#merkliste, div#project-details) {
      @apply w-full;
    }
  }

  &:has(body > div#root > div#statistik) {
    touch-action: none !important;
  }
}

@page {
  margin: 1cm;
}

@media print {
  .leaflet-control {
    display: none;
  }
}

div:has(>div>div.gm-iv-container.gm-iv-small-container) {
  @apply translate-y-[52px] translate-x-4;
}
